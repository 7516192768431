.App {
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;


  justify-content: center;
  align-items: center;
  font-family: Arial, sans serif;
  padding: 50px;

  overflow-x: hidden;
  overflow-y: scroll;
  background: linear-gradient(45deg, #3a85bb, #34b48d, #6cb9de, #23d5ab);
  background-size: 400% 400%;
  animation: gradientBG 15s ease infinite;

}

@keyframes gradientBG {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.App header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App link {
  color: #61dafb;
}

.UxBanner {
  max-width: 800px;
  background: #fff;
  opacity: .8;
  margin-left: auto;
  margin-right: auto;
  border-radius: 15px;
  margin-bottom: 50px;
  padding: 20px;
}

.UxBanner h1 {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 20px;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.UxBanner img {
  width: 150px;
}

.UxBanner p {
  max-width: 420px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
}

.UxFooter {
  max-width: 800px;
  background: #fff;
  opacity: .8;
  margin-left: auto;
  margin-right: auto;
  border-radius: 15px;
  padding: 20px;
  margin-top: 50px;

}

.UxFooter img {
  width: 100%;
}

.UxFooterImage {
  width: 100% !important;
  display: block !important
}

.UxFooterImage {
  max-height: 350px;
  width: 100%;
  display: block;
}

.UxFooterLogo {
  margin-top: 100px;
  height: 200px;
  border-radius: 100%;

}

.UxFooter p {
  padding: 50px;
  max-width: 500px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  color: #444
}

.UxFooter a {
  margin-top: 20px;
  padding: 15px;
  border-radius: 50px;
  background: #fff;
  display: inline-block;
  margin: 20px;
  color: #fff;
  background: #447BBE;
  border: 1px solid #fff;
}

.UxFooter p {
  padding: 5px;
}

.UxFooter p img {
  max-width: 500px
}