.UxTreatments {
    width: 100%;
    background: #fff;
    opacity: .8;
    border-radius: 15px;
    max-width: 840px;
    margin-left: auto;
    margin-right: auto;
}

.UxTreatments h1 {
    padding: 50px 30px 30px 30px;
}

.UxTreatment .shield {
    position: relative;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 50px;
    opacity: .9;
    background: #447bbe;
    background: #222;
}

.UxTreatments .UAllxTreatment {}

.UxTreatment {
    max-width: 300px;
    display: inline-block;
    padding: 10px;
    height: 200px;
    margin-bottom: 20px;
    overflow: hidden;
}

.UxTreatments img {
    width: 200px;
    height: 200px;
}

.UxTreatmentsImage {
    width: 100% !important;
    display: block !important
}

.UxTreatmentsImage {
    max-height: 350px;
    width: 100%;
    display: block;
}

.UxTreatmentsLogo {
    margin-top: -100px;
    height: 200px;
    border-radius: 100%;

}

.UxTreatments p {
    display: block;
    bottom: 0;
    opacity: .8;
    background: #447ABE;
    color: #eee;
    padding: 10px 20px;
    margin-top: -60px;
    margin-left: -40px;
    transform: rotate(45deg);
    width: 200px;
    text-align: left;
    font-size: 20px;
}